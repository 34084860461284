import { createApp } from "vue";
import EmployeeFeedback from "@/EmployeeFeedback.vue";
import TouchEvents from "vue3-touch-events";
import "./style.css";

document.addEventListener("DOMContentLoaded", function () {
  if (
    Array.isArray(EMPLOYEE_FEEDBACK_DATA) &&
    document.getElementById("employee-feedback") !== null
  ) {
    createApp(EmployeeFeedback, {
      data: EMPLOYEE_FEEDBACK_DATA,
    })
      .use(TouchEvents)
      .mount("#employee-feedback");
  }

  document
    .querySelector('[data-elem-id="1711477085146"] a[href="#respond"]')
    .addEventListener("click", function () {
      if (document.forms?.form740538760?.elements?.region) {
        document.forms.form740538760.elements.region.value = "Неизвестен";
      }
      if (document.forms?.form740538760?.elements?.vacancy) {
        document.forms.form740538760.elements.vacancy.value = "Неизвестна";
      }
    });
});
